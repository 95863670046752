.column-title {
  font-weight: 600;
  margin-bottom: 8px;
}

/* Set a fixed scrollable wrapper */
.tableWrap {
  height: 212px;
  max-height: 212px;
  overflow: auto;
  table {
    width: 100%;
  }
  thead tr th {
    position: sticky;
    top: 0;
  }
  td {
    padding: 8px 4px;
    text-align: center;
  }
  th {
    color: var(--app-medium-gray-color);
    background-color: var(--app-light-color);
    padding: 12px 4px;
  }
  .text-left {
    text-align: left;
  }
}
