*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body {
  height: 100%;
  overflow: hidden;
  font-size: 1.4rem;
  background: var(--app-light-gray-color);
}

:root {
  --app-primary-color: #00adca;
  --app-primary-opacity-color: #00aeeea2; //calendar
  --app-secondary-color: #EF4065;
  --app-light-color: #fff;
  --app-dark-color: #000;
  --app-light-gray-color: #eaeaea;
  --app-medium-gray-color: #c0bfbf;
  --app-dark-gray-color: #808080;
  --app-danger-color: #df2e34;
  --app-success-color: #79d368;
  --app-warning-color: #ffb818;
  --app-light-primary-color: #dffaff;
  --app-gradient-primary-color: linear-gradient(#00adca, #004E74);
  --app-transaparent-color: transparent;
  --app-gradient-background-color: linear-gradient(180deg, rgba(0,173,202,1) 0%, rgba(143,219,232,1) 24%, rgba(219,243,248,1) 45%, rgba(255,255,255,1) 100%);
  --app-gradient-light-primary-color: linear-gradient(180deg, #ffffff 0%, var(--app-light-primary-color) 100%);
  --app-light-flares-color: hsla(188, 100%, 67%, 0.3);
}

a {
  text-decoration: none;
  color: #000;
}
